<template>
    <div>
        <bread-crumb :navigationArray="navigationLink"/>
        <div class="white-container feedback">
            <div class="feedback__header">
              <h2>{{ getDetailPolls.title }}</h2>
            </div>
            <div class="feedback__templates">
              <p>{{ getDetailPolls.description }}</p>
              <iframe :src="getDetailPolls.externalForm" frameborder="0"
                      name="ya-form-5f8890b16c7ed60aa40b37c5">
              </iframe>
            </div>
        </div>
    </div>
</template>

<script>
import BreadCrumb from '@/components/BreadCrumb'

export default {
  name: 'FeedbackDetail',
  components: {
    BreadCrumb
  },
  data () {
    return {
      isActiveTab: 0,
      navigationLink: [
        {
          title: 'Главная',
          path: '/'
        },
        {
          title: 'Опросы граждан',
          path: '/feedback'
        },
        {
          title: 'Форма'
        }
      ],
      getDetailPolls: {}
    }
  },
  async beforeCreate () {
    console.log('Запрос к сервису GetMember передаёт id члена палаты')
    let res = await fetch(`${this.$store.getters.getUrlApi}api/survey/${this.$route.params.id}`)
    res = await res.json()
    console.log(await res)
    this.getDetailPolls = await res.data
    document.title = await res.data.title
  }
}
</script>

<style lang="scss" scoped>

.white-container{
    width: auto;
    margin: 0 3.8rem;
    padding: 2.5rem 2.94rem;
}

h2{
    font-weight: 600;
    font-size: 1.38rem;
    line-height: 1.75rem;
    color: #1F3245;
}

iframe{
  width: 100%;
  min-height: 50rem;
}

.feedback {
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.5rem;

  }

  &__templates p{
    font-size: 1rem;
    line-height: 1.75rem;
    color: #1F3245;
  }

}

@media screen and (max-width: 768px){
  .white-container{
    width: auto;
    margin: 0 .75rem;
    padding: 1.86rem 1.25rem;
  }

}
@media screen and (max-width: 420px){
    .white-container{
        width: 100%;
        margin: 0;
        padding: 1.88rem 1rem;
    }
    h2 {
      margin-bottom: 1.385rem;
    }

}
</style>
